<template>
  <div :style="`height:${height}px`">
    <iframe v-if="fileName"  height="100%" width="100%" :src="`${getFilePath}`" ></iframe>
    <!-- (GSP-090) 2nd instance of hidden `pdf-preview` to increase pdf and make screenshot for the PDF report (to avoid flickering = `changing preview size` on main instance)-->
    <!-- tried  deep `cloneNode` for 1st instance, but for the clone `contentDocument` (inner DOM with PDF-preview) return NULL, so this approach does not work-->

     <!-- `opacity: 0` = hide and 1 = show -->
      <!-- also to see 2nd iframe change `top: -2000px` to `top: -200px` -->
    <iframe v-if="fileName" :id="htmlId" style="opacity:0;position:absolute;top: -2000px" height="100%" width="100%" :src="`${getFilePath}`" ></iframe>
    <!-- <iframe height="100%" width=100% :src="fileLink" ></iframe> -->
  </div>
</template>

<script>
export default {
  name: 'pdfjs',
  props: {
    fileName: String,
    path: String,
    fileLink: String,
    height: Number,
    htmlId: String,
  },
  computed:{
    getFilePath: function() {
      console.log('this.fileName (ONLY THIS):>> ', this);
      console.log('this._props.fileName :>> ', this._props.fileName);
      // console.log('!!this.fileName :>> ', !!this.fileName);
      if(!!this && !!this._props.fileLink) {
          return this.path +'?file=' + this.fileLink;
      }
      return this?.path || '';
    }
  }
}
</script>
<style scoped>
div {
  width: 100%;
  min-width: 400px;
}
</style>